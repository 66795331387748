// General
$padding: 1rem;

// Default layout
$default-layout-top-bar-height: 3rem;
$default-layout-side-bar-width: 12rem;

// Breakpoints
$breakpoint-sm: 570px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

// Cards
$card-border-radius: 0.25rem;

:root {
    --breakpoint-default-layout: 40rem;
}
$breakpoint-default-layout: 40rem;

// Menu
$menu-item-height: 2.5rem;
$menu-selected-width: 0.4rem;

// Themes
$theme-primary: var(--primary);
$theme-primary-dark: var(--primary-dark);
$theme-primary-light: var(--primary-light);
$theme-secondary: var(--secondary);
$theme-secondary-dark: var(--secondary-dark);
$theme-secondary-light: var(--secondary-light);
$theme-background: var(--background);
$theme-surface: var(--surface);
$theme-error: var(--error);
$theme-on-primary: var(--on-primary);
$theme-on-secondary: var(--on-secondary);
$theme-on-background: var(--on-background);
$theme-on-surface: var(--on-surface);
$theme-on-error: var(--on-error);
$theme-hover: var(--hover);
$theme-overlay: var(--overlay);
