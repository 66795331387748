@media (prefers-color-scheme: light) {
    :root {
        // Primary
        --primary: #3f51b5;
        --primary-light: #757de8;
        --primary-dark: #002984;

        // Secondary
        --secondary: #00bbd3;
        --secondary-light: #62eeff;
        --secondary-dark: #008ba2;

        // Background and surface
        --background: hsl(100, 0%, 95%);
        --surface: #fff;
        --error: #b00020;

        // Contrast
        --on-primary: #fff;
        --on-secondary: #000;
        --on-background: #000;
        --on-surface: #000;
        --on-error: #fff;

        //  Miscellaneous
        --hover: hsl(0, 0%, 96%);
        --overlay: #000;
        --skeleton: hsl(100, 0%, 92%);
    }
}
