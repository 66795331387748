input[type] {
    color: var(--on-surface);
}

::selection {
    background-color: var(--primary);
    color: var(--on-primary);
}

.ngx-charts text {
    fill: var(--on-surface) !important;
}
