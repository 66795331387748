@media (prefers-color-scheme: dark) {
    :root {
        // Primary
        --primary: hsl(267, 95%, 76%);
        --primary-light: hsl(267, 95%, 86%);
        --primary-dark: hsl(267, 95%, 66%);

        // Secondary
        --secondary: hsl(174, 97%, 43%);
        --secondary-light: hsl(174, 97%, 53%);
        --secondary-dark: hsl(174, 97%, 33%);

        // Background and surface
        --background: hsl(0, 0%, 7%);
        --surface: hsl(0, 0%, 12%);
        --error: hsl(349, 52%, 61%);

        // Contrast
        --on-primary: hsl(0, 0%, 0%);
        --on-secondary: hsl(0, 0%, 0%);
        --on-background: hsl(0, 0%, 88%);
        --on-surface: hsl(0, 0%, 88%);
        --on-error: #fff;

        //  Miscellaneous
        --hover: hsl(0, 0%, 16%);
        --overlay: hsl(0, 0%, 5%);
        --skeleton: hsl(0, 0%, 7%);
    }
}
