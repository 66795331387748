@import './variables';
@import './mixins';
@import './themes';
@import './resets';

@import '~@angular/material/theming';
@include mat-core();

// Hue. Available color palettes: https://material.io/design/color/
// 500 default color
// 100 light color
// 700 dark color

$theme-primary: (100 : $theme-primary-light,
    500 : $theme-primary,
    700 : $theme-primary-dark,
    contrast: (100: $light-primary-text,
        500: $theme-on-primary,
        700: $light-primary-text));

$theme-secondary: (100 : $theme-secondary-light,
    500 : $theme-secondary,
    700 : $theme-secondary-dark,
    contrast: (100: $light-secondary-text,
        500: $theme-on-secondary,
        700: $light-secondary-text));

$theme-error: (100 : $theme-secondary-light,
    500 : $theme-error,
    700 : $theme-secondary-dark,
    contrast: (100: $light-primary-text,
        500: $theme-on-error,
        700: $light-primary-text));

$primary: mat-palette($theme-primary, 500, 100, 700);
$accent: mat-palette($theme-secondary, 500, 100, 700);
$warn: mat-palette($theme-error);

$theme-dark: mat-dark-theme($primary, $accent, $warn);
$theme-light: mat-light-theme($primary, $accent, $warn);

@media (prefers-color-scheme: dark) {
    @include angular-material-theme($theme-dark);
}

@media (prefers-color-scheme: light) {
    @include angular-material-theme($theme-light);
}
