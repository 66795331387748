@mixin font-base {
    font-size: 16px;
    font-weight: 400;
}

@mixin font-sm {
    font-size: 1rem;
    font-weight: 400;
}

@mixin font-ms {
    font-size: 1.25rem;
    font-weight: 500;
}

@mixin font-lg {
    font-size: 1.5rem;
    font-weight: 400;
}

@mixin font-xl {
    font-size: 2rem;
    font-weight: 700;
}
